import { onBeforeMount, reactive } from "vue"
import { dishDict, dishSystemDict } from '@/request/dishApi';

export default () => {
    const state = reactive({
        batchList: [],//批次/季度
        cuisineList1: [],//菜系类型1级
        cuisineList2: [],//菜系类型2级
        cuisineList3: [],//菜系类型3级
        flavorList1: [],// 风味1
        flavorList2: [],// 风味2
        materialList1: [],// 原料1
        materialList2: [],// 原料2
        materialList3: [],// 原料3
        processList: [],// 工艺
        styleTypeList: [],// 奉客形式
        templateList: [],// 大区模板
        regionList: [],// 大区
        provinceList: [],// 省份
        cityList: [],// 城市
        form: {
            batch: null,//批次
            brandShopNumMin: null,//品牌门店数min
            brandShopNumMax: null,//品牌门店数max
            priceMin: null,//门店客单价min
            priceMax: null,//门店客单价max
            mainType: [],//餐饮类型-菜系1级
            cuisineType: [],//菜系-菜系2级
            cuisine: [],//菜系明细-菜系3级
            flavorMainType: [],//风味1级
            flavorType: [],//风味2级
            materialMainType: [],//原料1级
            materialType: [],//原料2级
            materialSubType: [],//原料3级
            processMainType: [],//制作工艺
            styleType: [],//奉客形式
            templateIds: [],//大区模板
            regionIds: [],//大区
            province: [],//省份
            city: [],//城市
            size: null,//筛选条数
        },
    })

    // 回显搜索条件
    function reviewForm(query = {}) {
        state.form = query
        getChildDict()// 获取子级字典
    }

    // 获取批次/季度
    async function getBatch() {
        const { data } = await dishDict({ code: 'batch' })
        state.batchList = data || []
        state.form.batch = state.batchList[0]
    }

    // 获取菜系类型1级
    async function getCuisine1() {
        const { data } = await dishDict({ code: 'cuisine' })
        state.cuisineList1 = data || []
    }

    // 获取菜系类型2级
    async function getCuisine2() {
        const { data } = await dishDict({ code: 'cuisine1', cuisine1: state.form.mainType })
        state.cuisineList2 = data || []
    }

    // 获取菜系类型3级
    async function getCuisine3() {
        const { data } = await dishDict({ code: 'cuisine2', cuisine1: state.form.mainType, cuisine2: state.form.cuisineType })
        state.cuisineList3 = data || []
    }

    // 获取风味1级
    async function getFlavor1() {
        const { data } = await dishDict({ code: 'flavor' })
        state.flavorMainType = data || []
    }

    // 获取风味2级
    async function getFlavor2() {
        const { data } = await dishDict({ code: 'flavor1', flavor: state.form.flavorMainType })
        state.flavorList2 = data || []
    }

    // 获取原料1级
    async function getMaterial1() {
        const { data } = await dishDict({ code: 'material' })
        state.materialList1 = data || []
    }

    // 获取原料2级
    async function getMaterial2() {
        const { data } = await dishDict({ code: 'material1', material1: state.form.materialMainType })
        state.materialList2 = data || []
    }

    // 获取原料3级
    async function getMaterial3() {
        const { data } = await dishDict({ code: 'material2', material1: state.form.materialMainType, material2: state.form.materialType })
        state.materialList3 = data || []
    }

    // 获取制作工艺
    async function getProcess() {
        const { data } = await dishDict({ code: 'process' })
        state.processList = data || []
    }

    // 获取奉客形式
    async function getStyle() {
        const { data } = await dishDict({ code: 'style' })
        state.styleTypeList = data || []
    }

    // 获取大区模板
    async function getTemplate() {
        const { data } = await dishSystemDict({ code: 'template' })
        state.templateList = data || []
    }

    // 获取大区
    async function getRegion() {
        const { data } = await dishSystemDict({ code: 'region', templateIds: state.form.templateIds })
        state.regionList = data || []
    }

    // 获取省份
    async function getProvince() {
        const { data } = await dishDict({ code: 'province', templateIds: state.form.templateIds, regionIds: state.form.regionIds })
        state.provinceList = data || []
    }

    // 获取城市
    async function getCity() {
        const { data } = await dishDict({ code: 'city', templateIds: state.form.templateIds, regionIds: state.form.regionIds, province: state.form.province })
        state.cityList = data || []
    }

    // 菜系1级改变
    function changeCuisine1() {
        state.form.cuisineType = [];
        state.form.cuisine = [];
        getCuisine2()// 菜系2级
        getCuisine3()// 菜系3级
    }

    // 菜系2级改变
    function changeCuisine2() {
        state.form.cuisine = [];
        getCuisine3()// 菜系3级
    }

    // 风味1级改变
    function changeFlavor1() {
        state.form.flavorType = [];
        getFlavor2()// 风味2级
    }

    // 原料1级改变
    function changeMaterial1() {
        state.form.materialType = [];
        state.form.materialSubType = [];
        getMaterial2() //原料2级
        getMaterial3() //原料3级
    }

    // 原料2级改变
    function changeMaterial2() {
        state.form.materialSubType = [];
        getMaterial3() //原料3级
    }

    // 大区模板改变
    function changeTemplate() {
        state.form.regionIds = [];
        state.form.province = [];
        state.form.city = [];
        getRegion()// 大区
        getProvince()// 省份
        getCity()//城市
    }

    // 大区改变
    function changeRegion() {
        state.form.province = [];
        state.form.city = [];
        getProvince()// 省份
        getCity()//城市
    }

    // 省份改变
    function changeProvince() {
        state.form.city = [];
        getCity()//城市
    }

    // 初始化页面时获取下级条件列表
    function getChildDict() {
        getCuisine2()// 菜系2级
        getCuisine3()// 菜系3级
        getFlavor2()// 风味2级
        getMaterial2() //原料2级
        getMaterial3() //原料3级
        getRegion()// 大区
        getProvince()// 省份
        getCity()//城市
    }

    // 重置
    function resetFn() {
        state.form.batch = state.batchList[0];//批次
        state.form.brandShopNumMin = null;//品牌门店数min
        state.form.brandShopNumMax = null;//品牌门店数max
        state.form.priceMin = null;//门店客单价min
        state.form.priceMax = null;//门店客单价max
        state.form.mainType = [];//餐饮类型-菜系1级
        state.form.cuisineType = [];//菜系-菜系2级
        state.form.cuisine = [];//菜系明细-菜系3级
        state.form.flavorMainType = [];//风味1级
        state.form.flavorType = [];//风味2级
        state.form.materialMainType = [];//原料1级
        state.form.materialType = [];//原料2级
        state.form.materialSubType = [];//原料3级
        state.form.processMainType = [];//制作工艺
        state.form.styleType = [];//奉客形式
        state.form.templateIds = [];//大区模板
        state.form.regionIds = [];//大区
        state.form.province = [];//省份
        state.form.city = [];//城市
        state.form.size = 300;//筛选条数
    }

    onBeforeMount(() => {
        // 获取一级字典
        getBatch()// 季度
        getCuisine1()//菜系1级
        getFlavor1()// 风味1级
        getMaterial1()// 原料1级
        getProcess()//制作工艺
        getStyle()// 奉客形式
        getTemplate() //大区模板
    })

    return {
        state,
        reviewForm,
        changeFn: { changeCuisine1, changeCuisine2, changeFlavor1, changeMaterial1, changeMaterial2, changeTemplate, changeRegion, changeProvince },
        resetFn
    }
}