<template>
    <div class="generate_report" v-loading="state.loading">
        <!-- 筛选条件 -->
        <section class="screen_box">
            <!-- 基本条件 -->
            <div class="basic">
                <div class="row">
                    <div class="li">
                        <span class="label" style="width:60px">季度</span>
                        <el-select v-model="searchState.form.batch">
                            <el-option v-for="item in searchState.batchList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="li">
                        <span class="label">连锁类型（品牌门店数）</span>
                        <el-input style="width:120px" type="number" placeholder="最小门店数"
                            v-model.number="searchState.form.brandShopNumMin" oninput="value=value.replace(/[\D]/g,'')">
                        </el-input>-
                        <el-input style="width:120px" type="number" placeholder="最大门店数"
                            v-model.number="searchState.form.brandShopNumMax" oninput="value=value.replace(/[\D]/g,'')">
                        </el-input>
                    </div>
                    <div class="li">
                        <span class="label">门店客单价</span>
                        <el-input style="width:120px" type="number" placeholder="最小客单价"
                            v-model="searchState.form.priceMin">
                        </el-input>-
                        <el-input style="width:120px" type="number" placeholder="最大客单价"
                            v-model="searchState.form.priceMax">
                        </el-input>
                    </div>
                </div>
                <div class="row">
                    <div class="li">
                        <span class="label">菜系类型</span>
                        <el-select v-model="searchState.form.mainType" class="select" placeholder="餐饮类型" multiple
                            clearable collapse-tags @change="changeFn.changeCuisine1">
                            <el-option v-for="item in searchState.cuisineList1" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                        <el-select v-model="searchState.form.cuisineType" class="select" placeholder="菜系" multiple
                            clearable collapse-tags @change="changeFn.changeCuisine2">
                            <el-option v-for="item in searchState.cuisineList2" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                        <el-select v-model="searchState.form.cuisine" class="select" placeholder="菜系细项" multiple
                            clearable collapse-tags>
                            <el-option v-for="item in searchState.cuisineList3" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="li">
                        <span class="label">菜品风味</span>
                        <el-select v-model="searchState.form.flavorMainType" class="select" placeholder="风味大类" multiple
                            clearable collapse-tags @change="changeFn.changeFlavor1">
                            <el-option v-for="item in searchState.flavorMainType" :key="item" :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                        <el-select v-model="searchState.form.flavorType" class="select" placeholder="风味二级类" multiple
                            clearable collapse-tags>
                            <el-option v-for="item in searchState.flavorList2" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="row">
                    <div class="li">
                        <span class="label">菜品原料</span>
                        <el-select v-model="searchState.form.materialMainType" class="select" placeholder="原料大类"
                            multiple clearable collapse-tags @change="changeFn.changeMaterial1">
                            <el-option v-for="item in searchState.materialList1" :key="item" :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                        <el-select v-model="searchState.form.materialType" class="select" placeholder="原料二级类" multiple
                            clearable collapse-tags @change="changeFn.changeMaterial2">
                            <el-option v-for="item in searchState.materialList2" :key="item" :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                        <el-select v-model="searchState.form.materialSubType" class="select" placeholder="原料细类" multiple
                            clearable collapse-tags>
                            <el-option v-for="item in searchState.materialList3" :key="item" :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="li">
                        <span class="label">制作工艺</span>
                        <el-select v-model="searchState.form.processMainType" class="select" placeholder="制作工艺" multiple
                            clearable collapse-tags>
                            <el-option v-for="item in searchState.processList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="li">
                        <span class="label">奉客形式</span>
                        <el-select v-model="searchState.form.styleType" class="select" placeholder="奉客形式" multiple
                            clearable collapse-tags>
                            <el-option v-for="item in searchState.styleTypeList" :key="item" :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <!-- 区域选择 -->
            <div class="area">
                <h6>区域选择：</h6>
                <div class="row">
                    <!-- <div class="li">
                        <span class="label">区域名称</span>
                        <el-input placeholder="自定义区域名称" style="width:215px"></el-input>
                    </div> -->
                    <div class="li">
                        <span class="label">大区模板</span>
                        <el-select class="select" v-model="searchState.form.templateIds" multiple clearable
                            collapse-tags @change="changeFn.changeTemplate">
                            <el-option v-for="item in searchState.templateList" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="li">
                        <span class="label">大区</span>
                        <el-select class="select" v-model="searchState.form.regionIds" multiple clearable collapse-tags
                            @change="changeFn.changeRegion">
                            <el-option v-for="item in searchState.regionList" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="li">
                        <span class="label">省</span>
                        <el-select class="select" v-model="searchState.form.province" multiple clearable collapse-tags
                            @change="changeFn.changeProvince">
                            <el-option v-for="item in searchState.provinceList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="li">
                        <span class="label">市</span>
                        <el-select class="select" v-model="searchState.form.city" multiple clearable collapse-tags>
                            <el-option v-for="item in searchState.cityList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <!-- 筛选条数 -->
            <div class="area">
                <h6>其他：</h6>
                <div class="row">
                    <div class="li">
                        <span class="label">筛选条数</span>
                        <el-input type="number" placeholder="请输入" v-model.number="searchState.form.size"
                            oninput="value=value.replace(/[\D]/g,'')">
                        </el-input>
                    </div>
                </div>
            </div>
            <div class="btn">
                <el-button type="primary" plain @click="resetFn">重置</el-button>
                <el-button type="primary" @click="searchHandle">搜索</el-button>
            </div>
        </section>
        <!-- table -->
        <section class="table_box">
            <div class="top_box">
                <h6>第{{ pageState.page }}页（{{ state.total }}条）</h6>
                <div class="ri_btn">
                    <el-radio-group v-model="state.query.dishType" @change="queryChange">
                        <el-radio-button label="">全部</el-radio-button>
                        <el-radio-button label="MERGE">已合并</el-radio-button>
                        <el-radio-button label="ORIGIN">未合并</el-radio-button>
                    </el-radio-group>
                    <el-button type="danger" @click="deleteMuch">批量删除</el-button>
                </div>
            </div>
            <el-table :data="state.tableData" row-key="id" border default-expand-all
                :tree-props="{ children: 'children' }"
                :header-cell-style="{ background: '#f5f5f5', color: '#80899b', fontWeight: 400 }" height="600px" stripe
                size="small" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"
                    :selectable="(row) => row.dishType === 'ORIGIN' && (row.state === 0 || row.state === 3)" />
                <el-table-column prop="dishName" label="菜名" show-overflow-tooltip min-width="100px" />
                <el-table-column prop="recommendsNum" label="当期推荐数" align="center" width="100px" />
                <el-table-column prop="recommendsNumP" label="上期推荐数" align="center" width="100px" />
                <el-table-column prop="recommendsNumS" label="去年同期推荐数" align="center" width="100px" />
                <el-table-column prop="provinceNum" label="分布省份数" align="center" width="100px" />
                <el-table-column prop="province" label="最多的省份" align="center" width="100px" />
                <el-table-column prop="cityNum" label="分布城市数" align="center" width="100px" />
                <el-table-column prop="city" label="最多的城市" align="center" width="100px" />
                <el-table-column prop="mainTypeNum" label="餐饮类型数" align="center" width="100px" />
                <el-table-column prop="mainType" label="最多的餐饮类型" align="center" width="100px" />
                <el-table-column prop="cuisineTypeNum" label="菜系数" align="center" width="100px" />
                <el-table-column prop="cuisineType" label="最多的菜系" show-overflow-tooltip min-width="100px" />
                <el-table-column prop="brandNum" label="分布品牌数" align="center" width="100px" />
                <el-table-column prop="shopNum" label="分布门店数" align="center" width="100px" />
                <el-table-column prop="state" label="当前状态" align="center" width="100px">
                    <template #default="scope">
                        <span v-if="scope.row.state === 0" style="color:#67C23A">正常</span>
                        <span v-if="scope.row.state === 1" style="color:#F56C6C">已删除</span>
                        <span v-if="scope.row.state === 2">已合并</span>
                        <span v-if="scope.row.state === 3">已选图片</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="195px" fixed="right">
                    <template #default="scope">
                        <el-button type="text" size="small" v-if="scope.row.state === 0 || scope.row.state === 3"
                            @click="mergeOpen(scope.row)">
                            合并菜名</el-button>
                        <el-button type="text" size="small" v-if="scope.row.state === 0 || scope.row.state === 3"
                            @click="dicOpen(scope.row)">图片选择</el-button>
                        <el-button type="text" size="small" style="color:#F56C6C" v-if="scope.row.state !== 1"
                            @click="deleteConfirm(scope.row)">删除
                        </el-button>
                        <el-button type="text" size="small" v-if="scope.row.state === 2"
                            @click="cancelMerge(scope.row)">取消合并
                        </el-button>
                        <el-button type="text" size="small" v-if="scope.row.state === 1"
                            @click="cancelDelete(scope.row)">取消删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page_box">
                <el-pagination v-model:current-page="state.query.pageNum" v-model:page-size="state.query.pageSize"
                    :page-sizes="[10, 30, 50, 100]" background layout="sizes, prev, pager, next, jumper"
                    :total="state.tableTotal" />
            </div>
        </section>
        <!-- 下方操作栏 -->
        <section class="bottom_box">
            <div class="left_box">
                <span class="page">第{{ pageState.page }}页/共{{ state.pageInfo.total }}页</span>
                <div class="area_name">
                    <span>区域名称</span>
                    <el-input placeholder="自定义区域名称" style="width:215px" v-model="state.pageName" @blur="updatePageName">
                    </el-input>
                </div>
            </div>
            <div class="btn_box">
                <el-button type="primary" plain :disabled="pageState.page <= 1" @click="prePage">上一页</el-button>
                <el-button type="primary" plain :disabled="pageState.page >= state.pageInfo.total" @click="nextPage">下一页
                </el-button>
                <el-button type="primary" plain @click="createPage">新建页面</el-button>
                <el-button type="danger" plain :disabled="state.pageInfo.total === 1"
                    @click="delPage(state.pageInfo.pageId)">
                    删除页面</el-button>
                <el-button type="primary" plain :disabled="state.tableData.length === 0" @click="exportExcelRequest">
                    导出Excel</el-button>
                <el-button type="primary" @click="goToConfig">生成报告</el-button>
            </div>
        </section>
    </div>
    <!-- 菜品图片选择弹框 -->
    <dish-img-choose-dialog :show="dicState.show" @close="dicClose" :list="dicState.imgList"
        :dishName="dicState.row.name" @submitChooseImg="submitChooseImg"></dish-img-choose-dialog>
    <!-- 合并菜名弹框 -->
    <merge-name-dialog :dialogShow="mergeState.show" :mergeState="mergeState" :dishNum="searchState.form.size"
        @submitMerge="submitMerge" @savaKeyword="savaKeyword" @close="mergeClose">
    </merge-name-dialog>
</template>

<script setup>
import { onBeforeMount, reactive, watch } from 'vue';
import DishImgChooseDialog from './DishImgChooseDialog.vue';//菜品图片选择弹框
import useDishImgChooseDialog from './useDishImgChooseDialog';
import MergeNameDialog from './MergeNameDialog.vue';//合并菜名弹框
import useMergeName from './useMergeName';
import useDelete from './useDelete';
import { useRouter, useRoute } from 'vue-router';
import { reportPageGetInfo, reportDishList, reportDishHisList, reportPageUpdate, reportCheck } from '@/request/dishApi';
import useSearch from './useSearch';// 条件及字典
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus';
import usePage from './usePage';// 页面操作
import useExportExcel from './useExportExcel';//导出
import useReportTempStore from '@/store/reportTemp.js'

const $router = useRouter();
const $route = useRoute();

const reportTempStore = useReportTempStore()// 报告模板pinia

const state = reactive({
    reportId: Number($route.query.id),// 报告id
    loading: false,
    pageName: '',//当前页名称-区域名称
    pageInfo: {},//当前页信息，包含了页名称、query、总页数等
    // 分页及结果筛选条件，不包含页面上部分的条件
    query: {
        dishType: '',//MERGE-合并菜，ORIGIN-原始菜'
        pageNum: 1,
        pageSize: 10,
    },
    tableData: [],
    tableTotal: 0,//用于分页的总条数
    total: 0//总条数
})

watch(() => $route.query.page, (newVal) => {
    if ($route.path !== '/dishsearch/generatereport') return
    pageState.page = Number(newVal);
    pageChangeHandel()
})

// 页码改变后
function pageChangeHandel() {
    state.tableData = [];
    state.total = 0;
    getPageInfo()//获取当前报告页详情
}

// 获取当前报告页详情
async function getPageInfo() {
    const loading = ElLoading.service()
    const params = { pageNum: pageState.page, reportId: state.reportId }
    const { data } = await reportPageGetInfo(params)
    state.pageInfo = data || {} // 保存当前页信息
    state.pageName = state.pageInfo.name // 当前页名称-区域名称
    reviewForm(data?.query)// 回显条件
    if (state.pageInfo.query.search) getHistoryList()// 如果搜索过，去获取历史数据
    setTimeout(() => { loading.close() }, 1000)
}

// 搜索
function searchHandle() {
    if (searchState.form.size > 10000) {
        ElMessage.error('筛选条数最大10000条')
        searchState.form.size = 300
        return
    }
    ElMessageBox.confirm(
        '搜索后会重置合并及图片选择数据，确认继续？',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'error',
        }
    ).then(() => {
        getList();
    })
}

// 获取搜索结果列表
async function getList() {
    state.query.pageNum = 1;
    state.query.pageSize = 10;
    state.query.dishType = '';
    const loading = ElLoading.service({ text: '查询中，请稍后...' });
    const params = { ...searchState.form, pageId: state.pageInfo.pageId, ...state.query };
    const { data } = await reportDishList(params);
    state.tableData = data?.dish?.list || [];
    state.tableTotal = data?.dish?.total || 0;
    state.total = data?.total || 0;
    loading.close();
}

// 获取历史列表
async function getHistoryList() {
    const loading = ElLoading.service({ text: '查询中，请稍后...' });
    const { data } = await reportDishHisList({ pageId: state.pageInfo.pageId, ...state.query });
    state.tableData = data?.dish?.list || [];
    state.tableTotal = data?.dish?.total || 0;
    state.total = data?.total || 0;
    loading.close();
}

// 条件改变-合并类型
function queryChange() {
    state.query.pageNum = 1;
    getHistoryList();
}

// 分页
watch(() => state.query.pageNum, () => {
    getHistoryList();
})

// pageSize改变
watch(() => state.query.pageSize, () => {
    state.query.pageNum = 1;
    getHistoryList();
})

// 修改页面名称（区域名称）
async function updatePageName() {
    if (!state.pageName) {
        ElMessage.error('区域名称不能为空')
        return
    }
    const loading = ElLoading.service({ text: '查询中，请稍后...' })
    const { code } = await reportPageUpdate({ name: state.pageName, pageId: state.pageInfo.pageId })
    if (code === '0') {
        ElMessage({ type: 'success', message: '已保存' })
    }
    loading.close()
}

// 搜索条件及字典
const { state: searchState, reviewForm, changeFn, resetFn } = useSearch()

// 图片选择
const { state: dicState, close: dicClose, open: dicOpen, submitChooseImg } = useDishImgChooseDialog();

// 合并菜名
const { state: mergeState, close: mergeClose, open: mergeOpen, submitMerge, cancelMerge, savaKeyword } = useMergeName(state, getHistoryList);

// 删除
const { deleteConfirm, handleSelectionChange, deleteMuch, cancelDelete } = useDelete(getHistoryList);

// 页面操作
const { state: pageState, createPage, prePage, nextPage, delPage } = usePage(state, pageChangeHandel)

// 导出excel
const { exportExcelRequest } = useExportExcel(state)

// 跳转生成报告配置页
async function goToConfig() {
    state.loading = true;
    const { code } = await reportCheck({ reportId: state.reportId });
    state.loading = false;
    if (code === '0') {
        const tempList = reportTempStore.tempList;
        const obj = tempList.find(item => item.id === state.pageInfo.outcomeTemplate)
        if (!obj) return;
        $router.push({ path: obj.configPath, query: { id: state.reportId } })
    }
}

onBeforeMount(() => {
    pageState.page = Number($route.query.page);
    getPageInfo()// 获取当前报告页详情
})
</script>

<style lang="scss" scoped>
.generate_report {
    padding: 20px 40px;
    padding-bottom: 120px;

    .screen_box {
        padding: 20px;
        background-color: #fff;
        border-radius: 14px;
        margin-bottom: 20px;
        padding-bottom: 0;
        position: relative;
        padding-right: 100px;

        .basic,
        .area {
            .row {
                display: flex;
                flex-wrap: wrap;
            }

            h6 {
                margin-bottom: 15px;
                color: #666;
                font-size: 14px;
            }

            .li {
                display: flex;
                align-items: center;
                margin-right: 15px;
                margin-bottom: 20px;

                .label {
                    padding-right: 5px;
                    white-space: nowrap;
                    color: #666;
                }

                .select {
                    width: 180px;
                    margin-right: 5px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .area {
            .li {
                .select {
                    width: 200px;
                }
            }
        }

        .btn {
            position: absolute;
            right: 20px;
            bottom: 20px;
        }
    }

    .table_box {
        padding: 0 20px;
        background-color: #fff;
        border-radius: 14px;

        .top_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0;

            h6 {
                color: #666;
            }

            .ri_btn {
                display: flex;
                align-items: center;

                .el-button {
                    margin-left: 15px;
                    height: 32px;
                }
            }
        }

        .page_box {
            display: flex;
            padding: 20px;
            justify-content: flex-end;
        }
    }

    .bottom_box {
        width: 100%;
        min-width: 1200px;
        background-color: #fff;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        box-shadow: 0 -5px 10px #f2f2f2;

        span {
            color: #666;
        }

        .left_box {
            display: flex;
            align-items: center;

            .page {
                color: #666;
                padding-right: 15px;
            }

            .area_name {
                display: flex;
                align-items: center;

                span {
                    padding-right: 5px;
                    font-size: 14px;
                    color: #999;
                }
            }
        }
    }
}
</style>