import { reactive } from "vue";
import { ElMessageBox, ElMessage } from 'element-plus';
import { reportPageSave, reportPageDel } from '@/request/dishApi';
import { useRouter } from 'vue-router';

export default (parentState, pageChangeHandel) => {
    const $router = useRouter()
    const state = reactive({
        page: 1
    })

    // 新建页面
    function createPage() {
        if (!parentState.pageName) {
            ElMessage.error('请输入当前页区域名称')
            return
        }
        ElMessageBox.prompt('请输入名称', '新建页面', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            inputPattern: /[^/f/n/r/t/v]/,
            inputErrorMessage: '名称不能为空',
        }).then(({ value }) => {
            createPageRequest(value)
        })
    }

    // 新建页面request
    async function createPageRequest(name) {
        const { code } = await reportPageSave({ reportId: parentState.reportId, name: name })
        if (code === '0') {
            ElMessage({ type: 'success', message: '新建页面成功' })
            jumpPage(parentState.pageInfo.total + 1)
        }
    }

    // 上一页
    function prePage() {
        if (!parentState.pageName) {
            ElMessage.error('请输入当前页区域名称')
            return
        }
        if (state.page <= 1) return;
        state.page--;
        jumpPage(state.page)
    }

    // 下一页
    function nextPage() {
        if (!parentState.pageName) {
            ElMessage.error('请输入当前页区域名称')
            return
        }
        if (state.page >= parentState.pageInfo.total) return;
        state.page++;
        jumpPage(state.page)
    }

    // 删除页面
    function delPage(pageId) {
        ElMessageBox.confirm('确认删除？', '提示', { confirmButtonText: '确认', cancelButtonText: '取消', type: 'error' }).then(async () => {
            const { code } = await reportPageDel({ pageId: pageId })
            if (code === '0') {
                ElMessage({ type: 'success', message: '操作成功' });
                if (state.page === 1) {//如果删除的是第一页，那还是停留在第一页，且获取新的第一页的数据
                    pageChangeHandel()
                } else {
                    state.page--;
                }
                jumpPage(state.page);
            }
        })
    }

    // 跳转报告页
    function jumpPage(page) {
        $router.push({ path: '/dishsearch/generatereport', query: { id: parentState.reportId, page: page } })
    }

    return { state, createPage, prePage, nextPage, delPage }
}