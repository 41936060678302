import { ElMessageBox, ElLoading, ElMessage } from 'element-plus';
import { reportDishDel, reportDishBatchDel, reportDishCancelDel } from '@/request/dishApi';
import { reactive } from 'vue';

export default (getHistoryList) => {
    const state = reactive({
        multipleSelection: []
    })

    // 删除单个
    function deleteConfirm(row) {
        ElMessageBox.confirm('确定删除？', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'error' }
        ).then(async () => {
            const loading = ElLoading.service()
            const { code } = await reportDishDel({ id: row.id, dishType: row.dishType })
            if (code === '0') {
                ElMessage({ type: 'success', message: '操作成功' })
                getHistoryList()// 刷新列表
            }
            loading.close()
        })
    }

    // 多选监听
    function handleSelectionChange(val) {
        state.multipleSelection = val;
    }

    // 批量删除
    function deleteMuch() {
        if (state.multipleSelection.length <= 0) {
            ElMessage.error('请选择需要删除的数据')
            return
        }
        ElMessageBox.confirm('确定删除？', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'error' }
        ).then(async () => {
            const loading = ElLoading.service()
            const ids = state.multipleSelection.map(item => item.id)
            const { code } = await reportDishBatchDel({ ids: ids })
            if (code === '0') {
                ElMessage({ type: 'success', message: '操作成功' })
                getHistoryList()// 刷新列表
            }
            loading.close()
        })
    }

    // 取消删除
    async function cancelDelete(row) {
        const loading = ElLoading.service()
        const { code } = await reportDishCancelDel({ id: row.id, dishType: row.dishType })
        if (code === '0') {
            ElMessage({ type: 'success', message: '操作成功' })
            getHistoryList()// 刷新列表
        }
        loading.close()
    }

    return { deleteConfirm, handleSelectionChange, deleteMuch, cancelDelete }
}