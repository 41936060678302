<template>
    <el-dialog :model-value="dialogShow" title="合并菜名" width="1000px" top="2vh" @close="close" @opened="openedHandle">
        <div v-loading="mergeState.loading || state.loading">
            <!-- 合并过的菜名 -->
            <div class="tit_box"><span>合并过的菜名</span></div>
            <div class="listed">
                <div class="li" v-for="(item, index) in state.chooseYesList" :key="item.id">
                    <p>{{ item.dishName }}</p><span>({{ item.num }}次)</span>
                    <el-icon class="icon" @click="delDish(index)">
                        <CircleCloseFilled />
                    </el-icon>
                </div>
            </div>
            <el-divider />
            <!-- 未合并过的菜名 -->
            <div class="tit_box">
                <span>Top{{ dishNum }}中未合并过的菜名</span>
                <el-input placeholder="菜名查找" v-model="state.keyword" @change="state.isSearch = false">
                    <template #append>
                        <el-button :icon="Search" @click="searchFn">搜索</el-button>
                    </template>
                </el-input>
                <div class="history_list">
                    <span v-for="(item, index) in state.searchKeyList" :key="index" @click="onHistoryKey(item)">{{
                        item
                    }}</span>
                </div>
            </div>
            <div class="listed">
                <div class="li" v-for="(item, index) in chooseNoList" :key="item.id"
                    :style="{ display: item.show ? 'flex' : 'none' }" @click="addDish(index, item)">
                    <p>{{ item.dishName }}</p>
                </div>
            </div>
            <el-divider />
            <!-- 合并后菜名 -->
            <div class="tit_box">
                <span>合并后菜名</span>
                <el-input placeholder="合并菜品" v-model="state.dishName" @blur="state.isEdit = true"></el-input>
            </div>
            <div class="history_dish_name">
                <span>历史合并后菜名：</span>
                <span v-if="state.hisMergeNameList.length === 0">暂无</span>
                <ul>
                    <li v-for="item in state.hisMergeNameList" :key="item" @click="onHistoryDish(item)">{{
                        item.mergeDishName
                    }}</li>
                </ul>
            </div>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="submit" :disabled="mergeState.loading">确定</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import { CircleCloseFilled, Search } from '@element-plus/icons-vue';
import { reactive, computed } from 'vue';
import { ElMessage } from 'element-plus';
import { reportDishGetMergeData } from '@/request/dishApi';

const props = defineProps({
    dialogShow: { type: Boolean, required: true },
    mergeState: { type: Object, default: {} },
    dishNum: { type: Number, default: null }
})

const emits = defineEmits(['close', 'submitMerge', 'savaKeyword'])

const state = reactive({
    loading: false,
    baseDishId: '',//基准菜id
    keyword: '',// 搜索关键词
    dishName: '',// 合并菜名
    chooseYesList: [],//已选
    chooseNoList: [],//未选
    isCut: true,// 未选菜名是否显示前一百条，默认为true，搜索后显示全部，则为false
    searchKeyList: [],// 历史搜索词
    hisMergeNameList: [],// 历史合并后菜名
    isSearch: false,//是否允许保存关键词，关键词修改后会置为false，点了搜索为true，只有为true时，勾选菜品才向服务器保存历史关键词
    isEdit: false,// 合并菜名是否编辑过
})

// 未选-判断显示前100条还是全部
const chooseNoList = computed(() => {
    if (state.isCut) {
        return state.chooseNoList.slice(0, 100)
    } else {
        return state.chooseNoList
    }
})

// 打开弹框，赋值合并菜名
function openedHandle() {
    getData();
    const row = props.mergeState.row;
    // 如果打开的是合并菜
    if (row.dishType === 'MERGE') {
        state.dishName = row.dishName;
        state.isEdit = true;
    }
    // 如果打开的是基础菜
    if (row.dishType === 'ORIGIN') {
        state.dishName = '';
        state.isEdit = false;
    }
}

// 获取数据
async function getData() {
    state.loading = true;
    const row = props.mergeState.row;
    const { data } = await reportDishGetMergeData({ id: row.id, dishType: row.dishType })
    state.baseDishId = data?.baseDishId || null;
    state.chooseYesList = data?.upList || [];// 上面已选列表
    const downList = data?.downList || [];// 下面未选列表
    state.chooseNoList = downList.map(item => {
        item.show = true;
        return item;
    })
    state.searchKeyList = data?.searchKeyList || [];// 历史搜索次
    state.hisMergeNameList = data?.hisMergeNameList || [];
    getDefaultName();
    state.loading = false;
}

// 获取默认菜名
function getDefaultName() {
    if (state.isEdit) return;
    if (state.hisMergeNameList.length > 0) {
        state.dishName = state.hisMergeNameList[0].mergeDishName;
        state.isEdit = true;
    } else {
        const list = state.chooseYesList.sort((a, b) => b.recommendsNum - a.recommendsNum);
        state.dishName = list[0]?.dishName || '';
    }
}

// 搜索
function searchFn() {
    state.isSearch = true;
    let list = state.chooseNoList.map(item => {
        const reg = new RegExp(state.keyword, 'g')
        if (reg.test(item.dishName)) {
            item.show = true
        } else {
            item.show = false
        }
        return item
    })
    state.chooseNoList = list;// 赋值未选list
    if (state.keyword) {
        state.keyword && emits('savaKeyword', { dishName: props.mergeState.row.dishName, searchKey: state.keyword })// 保存关键词
        state.isCut = false;//如果关键词存在，就不截取前100条未选菜品，显示全部
    } else {
        state.isCut = true;//如果关键词存在，就截取前100条未选菜品，只显示前100
    }
}

// 删除菜
function delDish(index) {
    const arr = state.chooseYesList.splice(index, 1);
    state.chooseNoList.push(arr[0]);
    getDefaultName();//获取默认菜名
}

// 添加菜
function addDish(index, row) {
    const arr = state.chooseNoList.splice(index, 1);
    state.chooseYesList.push(arr[0]);
    (state.keyword && state.isSearch) && emits('savaKeyword', { dishName: row.dishName, searchKey: state.keyword });// 保存关键词
    getDefaultName();//获取默认菜名
}

// 点击历史关键词
function onHistoryKey(keyword) {
    state.keyword = keyword;
    searchFn();
}

// 点击历史合并后菜名
function onHistoryDish(e) {
    state.dishName = e.mergeDishName;
}

// 提交
function submit() {
    if (state.chooseYesList.length === 0) {
        ElMessage.error('请选择合并菜')
        return
    }
    if (!state.dishName) {
        ElMessage.error('请输入合并后菜名')
        return
    }
    const dishIds = state.chooseYesList.map(item => item.id)
    emits('submitMerge', { dishName: state.dishName, dishIds: dishIds, baseDishId: state.baseDishId })
}

// 关闭
function close() {
    state.keyword = '';
    state.dishName = '';
    state.chooseNoList = [];
    state.chooseYesList = [];
    state.hisMergeNameList = [];
    state.isSearch = false;
    state.isEdit = false;
    emits('close');
}
</script>

<style scoped lang="scss">
.tit_box {
    display: flex;
    align-items: center;
    padding-bottom: 15px;

    span {
        padding-right: 15px;
    }

    .el-input {
        max-width: 300px;
    }

    .history_list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 15px;

        span {
            padding-right: 15px;
            color: #999;
            cursor: pointer;

            &:hover {
                color: #409EFF;
            }
        }
    }
}

.listed {
    display: flex;
    flex-wrap: wrap;
    max-height: 500px;
    overflow-y: auto;

    .li {
        height: 32px;
        display: flex;
        align-items: center;
        border: 1px solid #cccc;
        margin-bottom: 15px;
        margin-right: 15px;
        padding: 0 15px;
        border-radius: 4px;
        cursor: pointer;

        .icon {
            margin-left: 5px;
            cursor: pointer;
            color: #ccc;
        }

        &.active {
            background-color: #409EFF;
            border-color: #409EFF;
            color: #fff;
        }
    }
}

.history_dish_name {
    display: flex;

    span {
        white-space: nowrap;
        line-height: 32px;
    }

    ul {
        display: flex;
        flex-wrap: wrap;

        li {
            height: 32px;
            display: flex;
            align-items: center;
            border: 1px solid #cccc;
            margin-bottom: 15px;
            margin-right: 15px;
            padding: 0 15px;
            border-radius: 4px;
            cursor: pointer;
        }
    }
}
</style>