import { reactive } from "vue";
import { reportDishMerge, reportDishCancelMerge, reportDishMergeSearch } from '@/request/dishApi';
import { ElLoading } from 'element-plus';

export default (praState, getHistoryList) => {

    const state = reactive({
        show: false,
        loading: false,
        row: {}
    })

    // 打开弹框
    function open(row) {
        state.show = true;
        state.row = row;
    }

    // 关闭弹框
    function close() {
        state.show = false;
        state.row = {};
    }

    // 保存关键字
    function savaKeyword(params) {
        reportDishMergeSearch(params)
    }

    // 合并提交
    async function submitMerge(p) {
        state.loading = true;
        const params = { ...p, pageId: praState.pageInfo.pageId }
        if (state.row.dishType === 'MERGE') params.mergeDishId = state.row.id;
        const { code } = await reportDishMerge(params)
        if (code === '0') {
            getHistoryList()
            close()
        }
        state.loading = false;
    }

    // 取消合并
    async function cancelMerge(row) {
        const loading = ElLoading.service()
        const { code } = await reportDishCancelMerge({ id: row.id, dishType: row.dishType })
        if (code === '0') {
            getHistoryList()
        }
        loading.close()
    }

    return { state, close, open, submitMerge, cancelMerge, savaKeyword }
}