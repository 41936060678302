import { reactive } from "vue";
import { reportDishGetDishImg, reportDishPickingImage } from '@/request/dishApi';
import { ElLoading, ElMessage } from 'element-plus';

export default () => {

    const state = reactive({
        show: false,
        row: {},
        imgList: []
    })

    // 获取图片列表
    async function getImgList(row) {
        const { data } = await reportDishGetDishImg({ id: row.id, dishType: row.dishType })
        let list = data?.list || []
        state.imgList = list.map(item => {
            const res = { isChecked: false, url: item }
            if (item === data.pickImg) res.isChecked = true;
            return res;
        })
    }

    // 打开
    function open(row) {
        state.show = true;
        state.row = row;
        state.imgList = []
        getImgList(row)
    }

    // 关闭
    function close() {
        state.show = false;
        state.row = {};
        state.imgList = []
    }

    // 提交
    async function submitChooseImg(pickImg) {
        const loading = ElLoading.service()
        const { code } = await reportDishPickingImage({ pickImg: pickImg, id: state.row.id, dishType: state.row.dishType })
        if (code === '0') {
            ElMessage({ type: 'success', message: '操作成功' })
            close()
        }
        loading.close()
    }

    return { state, close, open, submitChooseImg }
}