import { reportDishExport } from '@/request/dishApi';
import { ElLoading } from 'element-plus';
import { baseURL } from "@/request/http.js";
import { getStorage } from "@/utils";

export default (parentState) => {
    // 导出请求
    async function exportExcelRequest() {
        const loading = ElLoading.service()
        const token = getStorage("token");
        const href = `${baseURL}${reportDishExport()}?token=${token}&pageId=${parentState.pageInfo.pageId}`;
        const a = document.createElement("a");
        a.href = href;
        a.download = href;
        a.click();
        setTimeout(() => { loading.close() }, 1000)
    }
    return { exportExcelRequest }
}